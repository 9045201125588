import axios from "axios";
import { handleResponse, handleError } from "./response";
import urlcat from "urlcat";
import { msalConfig } from "@/index";

const BASE_URL = window.BASE_URL;

const getToken = async () => {
  const activeAccount = msalConfig.msalInstance.getActiveAccount();
  const accounts = msalConfig.msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const request = {
    scopes: [window.SCOPE_READ_B2C, window.SCOPE_WRITE_B2C],
    account: activeAccount || accounts[0],
  };

  const response = await msalConfig.msalInstance.acquireTokenSilent(request);
  return response.accessToken;
};

/** @param {string} service */
/** @param {string} resource */
/** @param {number} pageNumber */
/** @param {number} pageSize */
/** @param {string=} searchText */
/** @param {string} type */
const getAll = async (
  service,
  resource,
  pageNumber,
  pageSize,
  searchText,
  type
) => {
  let pathUrl = service + "/" + resource;
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };
  const queryParams = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    type: type,
    filterText: searchText,
  };
  return axios
    .get(urlcat(window.BASE_URL, pathUrl, queryParams), config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} service */
/** @param {string} resource */
/** @param {string} id */
const getSingle = async (service, resource, id) => {
  let pathUrl = service + "/" + resource + "/:id";
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };

  return axios
    .get(urlcat(window.BASE_URL, pathUrl, { id }), config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} service */
/** @param {string} resource */
/** @param {object} model */
const post = async (service, resource, model) => {
  let pathUrl = service + "/" + resource;
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };

  return axios
    .post(urlcat(window.BASE_URL, pathUrl), model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} service */
/** @param {string} resource */
/** @param {object} model */
const put = async (service, resource, id, model) => {
  let pathUrl = service + "/" + resource + "/:id";
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };

  return axios
    .put(urlcat(window.BASE_URL, pathUrl, { id }), model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} service */
/** @param {string} resource */
/** @param {object} model */
const patch = async (service, resource, model) => {
  let pathUrl = service + "/" + resource;
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };

  return axios
    .patch(urlcat(window.BASE_URL, pathUrl), model, config)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} service */
/** @param {string} resource */
/** @param {string} id */
const remove = async (service, resource, id) => {
  let pathUrl = service + "/" + resource;
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let config = {
    headers: {
      Authorization: bearer,
    },
  };

  let lvUrl = urlcat(window.BASE_URL, pathUrl);
  lvUrl += "/" + id;
  return axios.delete(lvUrl, config).then(handleResponse).catch(handleError);
};


const removeMultiple = async (service, resource, ids) => {
  let token = await getToken();
  const bearer = `Bearer ${token}`;
  let pathUrl = service + "/" + resource;

  const headers = {
    Authorization: bearer,
  };

  const deletePromises = ids.map(async (id) => {
    const lvUrl = urlcat(window.BASE_URL, pathUrl + "/" + id);
    try {
      const response = await axios.delete(lvUrl, { headers });
      return { status: response.status, id };
    } catch (error) {
      return { status: error.response ? error.response.status : 500, id };
    }
  });

  return Promise.all(deletePromises);
};

const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
  removeMultiple,
  getToken,
};

export default apiProvider;
