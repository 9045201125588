import { ApiCore } from "../utilities/core";
import axios from 'axios';
import { handleResponse, handleError } from '../utilities/response';
import urlcat from 'urlcat';
import apiProvider from "../utilities/provider";

const service = "accounts";
const resource = "user";

const accountsManagementTasks = new ApiCore({
    getAll: false,
    getSingle: true,
    post: false,
    put: false,
    patch:true,
    delete: false,
    service: service,
    resource: resource
});

const getUser = async () => {
    let pathUrl = service + "/" + resource;
    let token = await apiProvider.getToken();
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization: bearer,
        },
    };
  
    return axios
      .get(urlcat(window.BASE_URL, pathUrl), config)
      .then(handleResponse)
      .catch(handleError);
  };

export  {accountsManagementTasks, getUser};