import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useIdleTimer } from 'react-idle-timer'

interface Props {
  remainingTime: number;
  handleContinue: () => void;
  handleLogout: () => void;
}



const InactivityWarningPanel: React.FC<Props> = ({
  remainingTime,
  handleContinue,
  handleLogout,
}) => {


  return (
    <div className="inactivity-warning-panel">
        <h2>
        <FaExclamationTriangle className="caution-icon" />
        <span className="icon-text">Session Timeout Warning</span>
            </h2>
      <p>
        Your session will expire in: <span className="timer orange-text">{remainingTime} seconds</span>.
        Click Continue to keep working or Logout if you are finished.
        </p>
        <div className="button-container">
        <button className="continue-button" onClick={handleContinue}>Continue</button>
        <button className="sign-out-button" onClick={handleLogout}>Logout</button>
        </div>
      
    </div>
  );
}

export default InactivityWarningPanel;