import "antd/dist/reset.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import {
  Configuration,
  PublicClientApplication,
  EventType,
} from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ConfigProvider } from "antd";

export const msalConfig: { msalInstance: PublicClientApplication | null } = {
  msalInstance: null,
};

const setConfigVariables = async () => {
  await fetch("/config.json")
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((config) => {
      Object.keys(config).forEach((key) => {
        let value = config[key];
        (window as any)[key] = value;
      });
    })
    .catch((err) => console.error("Failed to load ./public/config.json", err));
};

setConfigVariables().then(function () {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  const protectedResources = {
    api: {
      endpoint: window.BASE_URL,
      scopes: {
        read: [window.SCOPE_READ_B2C],
        write: [window.SCOPE_WRITE_B2C],
      },
    },
  };

  const loginRequest = {
    scopes: [
      ...protectedResources.api.scopes.read,
      ...protectedResources.api.scopes.write,
    ],
  };

  const authRequest = {
    ...loginRequest,
  };

  const configuration: Configuration = {
    auth: {
      clientId: window.CLIENT_ID_B2C as string,
      authority: window.AUTHORITY_B2C_SIGNIN,
      redirectUri: window.REDIRECT_URL_B2C,
      knownAuthorities: [window.KNOWN_AUTHORITIES_B2C as string],
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  const pca = new PublicClientApplication(configuration);
  msalConfig.msalInstance = pca;

  const callbackId = pca.addEventCallback((message) => {
    if (message.eventType === EventType.LOGIN_FAILURE) {
      if (message.error?.message.includes("AADB2C90118")) {
        // The user has forgotten their password.
        const authority = window.AUTHORITY_B2C_RESETPASSWORD;
        pca.loginRedirect({
          authority: authority,
          scopes: loginRequest.scopes,
        });
      }
    }

    if (message.error?.message.includes("AADB2C90091")) {
      // The user has forgotten their password.
      const authority = window.AUTHORITY_B2C_SIGNIN;
      pca.loginRedirect({
        authority: authority,
        scopes: loginRequest.scopes,
      });
    }
  });

  root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ff4200",
          colorText: "#f0f0f6",
          colorBgBase: "#242424",
          colorBgLayout: "#f0f0f6",
          fontFamily: "AlumniSans-Light, sans-serif",
          fontSize: 16,
        },
        components: {
          Input: {
            colorBgContainer: "#c3c4c7",
            borderRadius: 9.6,
            colorText: "#737475",
            fontSize: 18,
            lineHeight: 2.0,
          },
          Card: {
            colorBgContainer: "#f0f0f6",
          },
          Table: {
            colorBgContainer: "#f0f0f6",
          },
        },
      }}
    >
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          authenticationRequest={authRequest}
          interactionType={InteractionType.Redirect}
        >
          <BrowserRouter>
            <App instance={pca} />
          </BrowserRouter>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </ConfigProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//App Insights config
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      window.AppinsightsConnectionString,
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
    autoTrackPageVisitTime: true,
    sessionRenewalMs: 1800000,
    sessionExpirationMs: 86400000,
    maxBatchSizeInBytes: 10000,
    maxBatchInterval: 15000,
    diagnosticLogInterval: 10000,
    overridePageViewDuration: true,
    maxAjaxCallsPerView: 500,
    disableDataLossAnalysis: true,
    enableAutoRouteTracking: true,
    disableExceptionTracking: false,
    disableTelemetry: false,
  },
});

//General methods
appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.trackException({ exception: new Error("error") });
appInsights.flush();
