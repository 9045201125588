import { notification } from 'antd';
import "./Notification.css";

export const showSuccessNotification = ({ title, message }: { title: string, message: string }) => {
    notification.success({
        message: title,
        description: message,
        duration: 4
    });
};

export const showErrorNotification = ({ title, message, duration }: { title: string, message: any, duration: number }) => {
    notification.error({
        message: title,
        description: message,
        duration: duration,
    });
};

export const showInfoNotification = ({ title, message, duration }: { title: string, message: any, duration: number }) => {
    notification.info({
        message: title,
        description: message,
        duration: duration,
    });
};