import { Dispatch, SetStateAction, Suspense, createContext, lazy, useCallback } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import React, { useState, useEffect } from "react";
import { Col, Layout, Row, ConfigProvider, Modal, Spin } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Menu from "antd/es/menu/menu";
import { MdOutlineEventAvailable } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { AiOutlineDollar } from "react-icons/ai";
import { MdOutlineSubscriptions } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { MdImage } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgSupport } from "react-icons/cg";
import logo from "./assets/images/Navlogo.png";
import { useMediaQuery } from "react-responsive";
import { FBUserInfo } from "./utils/EventsTypes";
import InactivityWarningPanel from "./components/inactivityWarningPanel";
import { useIdleTimer } from 'react-idle-timer'
import { GetHelp } from "./components/GetHelp/GetHelp";
import SupportPage from "./pages/support/Index";
const Events = lazy(() => import("./pages/events/Index"));
const Analytics = lazy(() => import("./pages/analytics/Analytics"));
const Settings = lazy(() => import("./pages/settings/Index"));
const NewEvent = lazy(() => import("./pages/events/NewEvent"));
const LiveVideo = lazy(() => import("./pages/live-stream/Index"));
const Subscription = lazy(
  () => import("./pages/settings/subscription/subscription")
);
const BigCommerceConnection = lazy(
  () => import("./pages/bigcommerce/BigCommerceConnection")
);
const Accounts = lazy(() => import("./pages/settings/accounts/Index"));
const Profile = lazy(() => import("./pages/settings/profile/Index"));
const EventCompleted = lazy(() => import("./pages/events/EventCompleted"));
const SplashPage = lazy(() => import("./pages/settings/profile/splash-page"));
const INACTIVITY_TIMEOUT = 1800000; // 30 minutes in milliseconds
const promptBeforeIdle = 59000; // 1 minute in milliseconds

type AppMenuContextProps = {
  isLive: boolean;
  setIsLive: (value: boolean) => void;
  setStreamEventID: (value: string) => void;
  channels: FBUserInfo;
  setChannels: Dispatch<SetStateAction<FBUserInfo>>;
  renderChannels: boolean;
  setRenderChannels: Dispatch<SetStateAction<boolean>>;
  fbFlag: boolean;
  setFBFlag: Dispatch<SetStateAction<boolean>>;
  missingStep: boolean;
  setMissingStep: Dispatch<SetStateAction<boolean>>;
  stepsCounter: number;
  setStepsCounter: Dispatch<SetStateAction<number>>;
};

export const AppContext = createContext<AppMenuContextProps>({
  isLive: false,
  setIsLive: () => { },
  setStreamEventID: () => { },
  channels: {},
  setChannels: () => { },
  renderChannels: false,
  setRenderChannels: () => { },
  fbFlag: false,
  setFBFlag: () => { },
  missingStep: false,
  setMissingStep: () => { },
  stepsCounter: 0,
  setStepsCounter: () => { },
});

const App: React.FC<{ instance: PublicClientApplication }> = ({ instance }) => {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [confirmLeaveModalVisible, setConfirmLeaveModalVisible] = useState<boolean>(false);
  const [isLive, setIsLiveState] = useState<boolean>(false);
  const [streamEventID, setStreamEventID] = useState<string>("");
  const [navigateTo, setNavigateTo] = useState<string>("");
  const [channels, setChannels] = useState<FBUserInfo>({ channels: [] });
  const [renderChannels, setRenderChannels] = useState<boolean>(false);
  const [fbFlag, setFBFlag] = useState<boolean>(false);
  const [missingStep, setMissingStep] = useState<boolean>(false);
  const [stepsCounter, setStepsCounter] = useState<number>(0);
  const defaultConfig = {
    INACTIVITY_TIMEOUT_MS: INACTIVITY_TIMEOUT,
  };
  const [config, setConfig] = useState<any>(defaultConfig);
  const [state, setState] = useState<string>('Active')
  const [remaining, setRemaining] = useState<number>(config.INACTIVITY_TIMEOUT)
  const [open, setOpen] = useState<boolean>(false);

  const fetchConfig = useCallback(() => {
    fetch("/config.json")
      .then((response) => response.json())

      .then((data) => {
        setConfig(data.INACTIVITY_TIMEOUT_MS);
      })

      .catch((error) => {
        console.error("Error loading config:", error);
      });
  }, [])

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    if (getRemainingTime() <= promptBeforeIdle) {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      setOpen(true);
    }
  }

  const navigateWithConfirmation = (key: string) => {
    if (isLive) {
      setConfirmLeaveModalVisible(true);
    } else {
      navigate(key);
      isMobile && setCollapsed(true);
    }
  };

  const handleLeaveConfirmation = () => {
    setIsLive(false);
    setConfirmLeaveModalVisible(false);
    navigate(navigateTo);
    isMobile && setCollapsed(true);
  };

  const handleCancelLeave = () => {
    setConfirmLeaveModalVisible(false);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: config,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const remainingTime = getRemainingTime();

    if (remainingTime <= promptBeforeIdle) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    } 

  }, [remaining]);
  
  const handleLogout = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  useEffect(() => {
    if (remaining === 0) {
      handleLogout()
    }
  }, [handleLogout, remaining]);

  const handleContinue = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  const setIsLive = (value: boolean) => {
    setIsLiveState(value);
  };

  return (
    <Layout className="container">
      <Header
        style={{
          backgroundColor: "#242424",
        }}
      >
        <Row>
          <Col span={3} style={{ display: "flex", alignItems: "center" }}>
            <GiHamburgerMenu
              onClick={() => setCollapsed(!collapsed)}
              size={30}
            />
          </Col>
          <Col
            span={18}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5rem",
            }}
          >
            <img src={logo} alt="logo" className="app-logo" />
          </Col>
          <Col span={3}></Col>
        </Row>
      </Header>
      <div>
        <div
          className='modal'
          style={{
            display: open ? 'flex' : 'none'
          }}>
          <InactivityWarningPanel
            remainingTime={remaining || 0}
            handleContinue={handleContinue}
            handleLogout={handleLogout}
          />
        </div>
      </div>
      <Layout>
        <Sider
          theme="light"
          collapsed={collapsed}
          className={!collapsed ? "sider-collapsed" : ""}
        >
          <Menu
            onClick={({ key }) => {
              if (key === "logout") {
                handleLogout();
                setIsLive(false);
              } else {
                setNavigateTo(key);
                navigateWithConfirmation(key);
              }
            }}
            mode="inline"
            hidden={collapsed}
            defaultSelectedKeys={["home"]}
            items={[
              {
                label: "Events",
                key: "/",
                icon: <MdOutlineEventAvailable />,
              },
              {
                label: "Analytics",
                key: "/analytics",
                icon: <TbReportAnalytics />,
              },
              {
                label: "Settings",
                key: "/settings",
                icon: <IoSettingsOutline />,
                children: [
                  {
                    label: "Profile",
                    key: "/profile",
                    icon: <CgProfile />,
                  },
                  {
                    label: "Splash Page",
                    key: "/settings/profile/splash-page",
                    icon: <MdImage />,
                  },
                  {
                    label: "Billing",
                    key: "/billing",
                    icon: <AiOutlineDollar />,
                    children: [
                      {
                        label: "Subscription",
                        key: "/settings/billing/subscription",
                        icon: <MdOutlineSubscriptions />,
                      },
                    ],
                  },
                  {
                    label: "Accounts",
                    key: "/accounts",
                    icon: <MdManageAccounts />,
                  },
                ],
              },
              {
                label: "Support",
                key: "/support",
                icon: <CgSupport />
              },
              {
                label: "Logout",
                key: "logout",
                icon: <BiLogOut />,
              },
            ]}
          />
        </Sider>
        <Content
          className={!collapsed ? `content content-collapsed` : "contentFull"}
          style={{ overflow: "auto" }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorText: "#242424",
              },
            }}
          >
            <AppContext.Provider
              value={{
                isLive, setIsLive, setStreamEventID, channels, setChannels,
                renderChannels, setRenderChannels, fbFlag, setFBFlag,
                missingStep, setMissingStep, stepsCounter, setStepsCounter
              }}
            >
              <GetHelp />
              <Suspense
                fallback={
                  <div className="loadingContainer">
                    <Spin
                      tip={
                        <span
                          style={{ fontSize: "1rem", marginTop: "0.75rem" }}
                        >
                          Loading
                        </span>
                      }
                      size="large"
                      style={{ backgroundColor: "#f0f0f6" }}
                    >
                      <div className="content" />
                    </Spin>
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={<Events />} />
                  <Route path="/NewEvent" element={<NewEvent />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/account" element={<Settings />} />
                  <Route
                    path="/settings/billing/subscription"
                    element={<Subscription />}
                  />
                  <Route path="/live-video" element={<LiveVideo />} />
                  <Route path="/bcsetup" element={<BigCommerceConnection />} />
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/profile" element={<Profile />} />

                  <Route
                    path="/settings/profile/splash-page"
                    element={<SplashPage />}
                  />
                  <Route path="/support" element={<SupportPage />} />

                  <Route path="/event/completed" element={<EventCompleted />} />
                </Routes>
              </Suspense>

            </AppContext.Provider>
          </ConfigProvider>
        </Content>
      </Layout>
      <Modal
        centered
        title="There is a live stream in progress.  Continuing will end the live stream.  Do you want to continue?"
        open={confirmLeaveModalVisible}
        onOk={handleLeaveConfirmation}
        onCancel={handleCancelLeave}
        okText="Yes"
        okButtonProps={{
          type: "primary",
          style: { margin: "0.6rem", padding: "1.5rem" },
          disabled: !isLive
        }}
        cancelText="No"
        cancelButtonProps={{ style: { color: "white", padding: "1.5rem" } }}
        closable={false}
      >
      </Modal>
    </Layout>
  );
};

export default App;
