import { ApiCore } from "../utilities/core";
import axios from 'axios';
import { handleResponse, handleError } from '../utilities/response';
import urlcat from 'urlcat';
import apiProvider from "../utilities/provider";

const service = "settings";
const resource = "splashpage";

const settingsTasks = new ApiCore({
    getAll: false,
    getSingle: true,
    post: true,
    put: false,
    patch:false,
    delete: false,
    service: service,
    resource: resource
});

const splashPageGetBybigCommerceId = async (bigCommerceId) => {
    let pathUrl = service + "/" + resource + "/:bigCommerceId";
    let token = await apiProvider.getToken();
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization : bearer
        }
    }

    return axios
        .get(urlcat(window.BASE_URL,pathUrl, { bigCommerceId }), config)
        .then(handleResponse)
        .catch(handleError);
}

const splashPagePostbyBigCommerceId = async (bigCommerceId, model) => {
    let pathUrl = service + "/" + resource + "/:bigCommerceId";
    let token = await apiProvider.getToken();
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization : bearer
        }
    }
    
    return axios
        .post(urlcat(window.BASE_URL, pathUrl, { bigCommerceId }), model, config)
        .then(handleResponse)
        .catch(handleError);
}

const sendSupportEmail = async ( model) => {
    let pathUrl = service + "/" + "sendGrip/Support" ;
    let token = await apiProvider.getToken();
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization : bearer
        }
    }
    
    return axios
        .post(urlcat(window.BASE_URL, pathUrl), model, config)
        .then(handleResponse)
        .catch(handleError);
}

export { splashPageGetBybigCommerceId, splashPagePostbyBigCommerceId, sendSupportEmail };