import apiProvider from './provider';
import polly from "polly-js";

export class ApiCore {
    constructor(options) {
        if (options.getAll) {
            this.getAll = (pageNumber, pageSize, filterText, type) => {
                return apiProvider.getAll(options.service, options.resource, pageNumber, pageSize, filterText, type);
            };
        }

        if (options.getSingle) {
            this.getSingle = (id) => {
                return apiProvider.getSingle(options.service, options.resource, id);
            };
        }

        if (options.post) {
            this.post = (model) => {
                return apiProvider.post(options.service, options.resource, model);    
            };
        }

        if (options.postWithPathParams) {
            this.postWithPathParams = (params,model) => {
                return apiProvider.postWithPathParams(options.service, params, options.resource, model);
            };
        }

        if (options.put) {
            this.put = (resource, id, model) => {
                return apiProvider.put(options.service, resource, id, model);
            };
        }

        if (options.patch) {
            this.patch = (model) => {
                return apiProvider.patch(options.service, options.resource, model);
            };
        }

        if (options.remove) {
            this.remove = (id) => {
                return apiProvider.remove(options.service, options.resource, id);
            };
        }

        if(options.removeMultiple){
            this.removeMultiple = (ids) => {
                return apiProvider.removeMultiple(options.service, options.resource, ids);
            }
        }
    }
}